import { Button, Icon, Popconfirm } from 'antd';
import queryString from 'query-string';
import React, { FC, useContext } from 'react';
import config from '../../../config';
import { history } from '../../core/components/App/history';
import { AppContext } from '../../core/contexts/AppContext';
import { DATE_RANGE, getDateRange } from '../../referrals/components/ReferralsTable/ReferralsDateRange';
import { QueryParamsInterface } from '../../referrals/pages/ReferralsContainer';

export enum SortOrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SortFieldEnum {
  INSERTED_AT = 'INSERTED_AT',
  APPT_TIME = 'APPT_TIME',
}

interface QueryVariablesInterface {
  startDate: string;
  endDate: string;
  filters: string | string[] | undefined;
  locationId: string | undefined;
  sortField?: string | string[] | SortFieldEnum;
  sortOrder?: string | string[] | SortOrderEnum;
}

export const AppointmentsCSVDownload: FC = (): JSX.Element => {
  const { currentOrganization, currentLocation } = useContext(AppContext);

  const getQueryVariables = (): QueryVariablesInterface => {
    const queryParams: QueryParamsInterface = queryString.parse(history.location.search);
    return {
      startDate:
        queryParams.startDate ||
        getDateRange(currentOrganization?.defaultDateFilterRange || DATE_RANGE.LAST_30_DAYS).fromDate.toISOString(),
      endDate:
        queryParams.endDate ||
        getDateRange(currentOrganization?.defaultDateFilterRange || DATE_RANGE.LAST_30_DAYS).toDate.toISOString(),
      filters: queryParams.filters ? queryParams.filters : '{}',
      locationId: currentLocation?.id,
      sortOrder: queryParams.sortOrder ? queryParams.sortOrder : 'ASC',
      sortField: queryParams.sortField ? queryParams.sortField : 'APPT_TIME',
    };
  };

  const handleWithoutFilters = (): void => {
    const queryVars = getQueryVariables();
    queryVars.filters = undefined;

    const queryVarsString = queryString.stringify(queryVars, { skipNull: true });

    openTab(queryVarsString);
  };

  const handleWithFilters = (): void => {
    const queryVars = getQueryVariables();
    const queryVarsString = queryString.stringify(queryVars, { skipNull: true });
    openTab(queryVarsString);
  };

  const openTab = (params: string): void => {
    window.open(`${config.endpoint}/download/organizations/${currentOrganization?.id}/consumer_appointments?${params}`);
  };

  return (
    <div>
      <Popconfirm
        title="Export with filters?"
        cancelText="No"
        okText="Yes"
        onCancel={handleWithoutFilters}
        onConfirm={handleWithFilters}
      >
        <Button htmlType="button">
          CSV Export <Icon type="cloud-download" />
        </Button>
      </Popconfirm>
    </div>
  );
};
