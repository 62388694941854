import { gql, useMutation } from '@apollo/client';

const CREATE_LINK_TYPE_MUTATION = gql`
  mutation CreateLinkType($createLinkTypeInput: CreateLinkTypeInput!) {
    createLinkType(createLinkTypeInput: $createLinkTypeInput) {
      id
      linkType
      linkTypeScope
      organizationId
    }
  }
`;

interface LinkType {
  id: string;
  linkType: string;
  linkTypeScope: string;
  organizationId: string;
}

interface CreateLinkTypeData {
  createLink: LinkType;
}

interface CreateLinkTypeVars {
  createLinkTypeInput: {
    linkType: string;
    linkTypeScope: string;
    organizationId: string;
  };
}

export const useCreateLinkTypeMutation = () => {
  return useMutation<CreateLinkTypeData, CreateLinkTypeVars>(CREATE_LINK_TYPE_MUTATION);
};
