import { gql, useMutation } from '@apollo/client';

// Define the GraphQL mutation
const DELETE_LINK_TYPE_MUTATION = gql`
  mutation DeleteLinkType($id: ID!) {
    deleteLinkType(id: $id) {
      id
    }
  }
`;

interface DeleteLinkTypeData {
  deleteLinkType: {
    id: string;
  };
}

interface DeleteLinkTypeVars {
  id: string;
}

export const useDeleteLinkTypeMutation = () => {
  return useMutation<DeleteLinkTypeData, DeleteLinkTypeVars>(DELETE_LINK_TYPE_MUTATION);
};
