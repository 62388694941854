import { gql, useQuery } from '@apollo/client';

const LINK_TYPES_QUERY = gql`
  query LinkTypes($filter: LinkTypeFilter) {
    linkTypes(filter: $filter) {
      id
      linkType
      linkTypeScope
      organizationId
    }
  }
`;

interface LinkType {
  id: string;
  description: string;
  label: string;
  linkType: string;
  linkTypeScope: string;
  locationId: string;
  profileId: string;
}

interface GetLinkTypesData {
  linkTypes: LinkType[];
}

interface LinkTypeFilter {
  id?: string;
  linkType?: string;
  linkTypeScope?: string;
  organizationId?: string;
}

interface GetLinkTypesVars {
  filter?: LinkTypeFilter;
}

export const useGetLinkTypesQuery = (variables: GetLinkTypesVars) => {
  const filteredVariables = Object.fromEntries(Object.entries(variables).filter(([_, v]) => v !== undefined));
  return useQuery<GetLinkTypesData, GetLinkTypesVars>(LINK_TYPES_QUERY, {
    variables: filteredVariables,
  });
};
