import { gql, useMutation } from '@apollo/client';

// Define the GraphQL mutation
const UPDATE_LINK_TYPE_MUTATION = gql`
  mutation UpdateLinkType($updateLinkTypeInput: UpdateLinkTypeInput!) {
    updateLinkType(updateLinkTypeInput: $updateLinkTypeInput) {
      id
      linkType
      linkTypeScope
      organizationId
    }
  }
`;

interface LinkType {
  id: string;
  linkType: string;
  linkTypeScope: string;
  organizationId: string;
}

interface UpdateLinkTypeData {
  updateLinkType: LinkType;
}

interface UpdateLinkTypeVars {
  updateLinkTypeInput: {
    id: string;
    linkType: string;
    organizationId: string;
  };
}

export const useUpdateLinkTypeMutation = () => {
  return useMutation<UpdateLinkTypeData, UpdateLinkTypeVars>(UPDATE_LINK_TYPE_MUTATION);
};
