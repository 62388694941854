import { Button, Card, Col, Form, Icon, Input, message, Row, Select, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { FC, useEffect, useState } from 'react';
import { useGetLinkTypesQuery } from '../../../hooks/queries/useGetLinkTypesQuery';
import { useCreateLinkTypeMutation } from '../../../hooks/mutations/useCreateLinkTypeMutation';
import { useDeleteLinkTypeMutation } from '../../../hooks/mutations/useDeleteLinkTypeMutation';
import { useUpdateLinkTypeMutation } from '../../../hooks/mutations/useUpdateLinkTypeMutation';
import { ViewerOrganization } from '../../viewer';
const { Option } = Select;

interface Props extends FormComponentProps {
  currentOrganization: ViewerOrganization;
}

const LinkTypesCardComponent: FC<Props> = ({ form, currentOrganization }): JSX.Element => {
  const [createLinkType] = useCreateLinkTypeMutation();
  const [deleteLinkType] = useDeleteLinkTypeMutation();
  const [updateLinkType] = useUpdateLinkTypeMutation();

  const [editing, setEditing] = useState<string[]>([]);
  const [linkTypeValues, setLinkTypeValues] = useState<{ [key: string]: string }>({});
  const [linkType, setLinkType] = useState('');
  const [linkTypeScope, setLinkTypeScope] = useState('');

  const handleLinkTypeSelection = async (linkType: string) => setLinkType(linkType);
  const handleScopeSelection = async (scope: string) => setLinkTypeScope(scope);

  const { data, refetch } = useGetLinkTypesQuery({});

  useEffect(() => {
    if (data?.linkTypes) {
      const linkTypeValues = data.linkTypes.reduce((acc: { [key: string]: string }, lt: any) => {
        acc[lt.id] = lt.linkType;
        return acc;
      }, {});
      setLinkTypeValues(linkTypeValues);
    }
  }, [data]);

  const handleAddLink = async (e: any) => {
    e.preventDefault();

    // prettier-ignore
    try {
      await createLinkType({
        variables: {
          createLinkTypeInput: {
            linkType,
            linkTypeScope,
            organizationId: currentOrganization.id,
          },
        },
      });
      refetch();
      setLinkType('');
      setLinkTypeScope('');

      message.success('Link type created');
    } catch (error: any) {
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const errorMsg = error.graphQLErrors[0].message;
        if (errorMsg.includes("Invalid organization_id")) {
          message.error("This user does not have an organization to assign the LinkType to.");
        } else {
          message.error(errorMsg);
        }
      } else {
        message.error('An error occurred while creating the link type');
      }
    }
  };

  const handleDelete = async (linkTypeId: string) => {
    try {
      await deleteLinkType({ variables: { id: linkTypeId } });
      refetch();
    } catch (error) {
      message.error('An error occurred while deleting the link type');
    }
  };

  const saveLinkType = (id: string, linkType: string) => async () => {
    // prettier-ignore
    try {
      await updateLinkType({
        variables: {
          updateLinkTypeInput: {
            id,
            linkType,
            organizationId: currentOrganization.id,
          },
        },
      });

      setEditing(editing.filter(editingId => editingId !== id));
      refetch();

      message.success('Link type updated');
    } catch (error: any) {
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const graphQLError = error.graphQLErrors[0];
        message.error(graphQLError.message);
      } else {
        message.error('An error occurred while updating the link type');
      }
    }
  };

  const toggleEdit = (id: string) => () => {
    if (editing.includes(id)) {
      setEditing(editing.filter(editingId => editingId !== id));
    } else {
      setEditing([...editing, id]);
    }
  };

  const columns = [
    {
      title: 'Link Type Scope',
      key: 'LINK_TYPE_SCOPE',
      sorter: (a: any, b: any) => a.linkTypeScope.localeCompare(b.linkTypeScope),
      render: (lt: any): JSX.Element => <div className="main-text">{lt.linkTypeScope}</div>,
    },
    {
      title: 'Link Type',
      key: 'LINK_TYPE',
      sorter: (a: any, b: any) => a.linkType.localeCompare(b.linkType),
      render: (lt: any): JSX.Element => {
        if (editing.includes(lt.id)) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Input
                value={linkTypeValues[lt.id]}
                onChange={e => {
                  const value = e.target.value;
                  setLinkTypeValues(prevValues => ({
                    ...prevValues,
                    [lt.id]: value,
                  }));
                }}
              />
              <Icon type="save" onClick={saveLinkType(lt.id, linkTypeValues[lt.id])} className="ml-4"></Icon>
            </div>
          );
        } else {
          return <div className="main-text">{lt.linkType}</div>;
        }
      },
    },
    {
      sorter: false,
      render: (lt: any): JSX.Element => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Icon type="edit" onClick={toggleEdit(lt.id)}></Icon>
          <Icon onClick={() => handleDelete(lt.id)} type="close"></Icon>
        </div>
      ),
    },
  ];

  return (
    <Row>
      <Col span={18} push={1}>
        <Card title="Link Type Management">
          <Row align="top">
            <Col span={12}>
              <Form.Item label="Link Type Scope">
                <Select value={linkTypeScope} onChange={e => handleScopeSelection(e)}>
                  <Option value="location">Location</Option>
                  <Option value="profile">Profile</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={11} push={1}>
              <Form.Item label="Link Type">
                <Input
                  placeholder="Enter link type"
                  value={linkType}
                  onChange={e => handleLinkTypeSelection(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mb-6">
            <Col span={24} className="text-right">
              <Button onClick={handleAddLink} type="primary">
                Add Link Type
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: '4rem' }}>
            {data?.linkTypes && data?.linkTypes.length > 0 ? (
              <Table
                sortDirections={['ascend', 'descend']}
                columns={columns}
                rowKey={(lt): string => lt.id}
                dataSource={data.linkTypes}
                pagination={{ pageSize: 10 }}
              />
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p>No links found</p>
              </div>
            )}
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export const LinkTypesCard = Form.create<Props>({ name: 'Email Settings' })(LinkTypesCardComponent);
