import { useQuery, QueryHookOptions, QueryResult } from '@apollo/client';
import { gql } from '@apollo/client';

interface Data {
  getTelehealthAttendee: {
    chimeMeeting: {
      audioFallbackUrl: string;
      audioHostUrl: string;
      screenDataUrl: string;
      screenSharingUrl: string;
      screenViewingUrl: string;
      signalingUrl: string;
      turnControlUrl: string;
      mediaRegion: string;
      meetingId: string;
      externalMeetingId: string;
    };
    chimeAttendee: {
      attendeeId: string;
      externalUserId: string;
      joinToken: string;
    };
  };
}

interface Variables {
  appointmentId?: string;
  attendeeName: string;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

const GetTelehealthAttendeeQuery = gql`
  query GetTelehealthAttendee($appointmentId: ID!, $attendeeName: String!) {
    getTelehealthAttendee(appointmentId: $appointmentId, attendeeName: $attendeeName) {
      chimeMeeting {
        audioFallbackUrl
        audioHostUrl
        screenDataUrl
        screenSharingUrl
        screenViewingUrl
        signalingUrl
        turnControlUrl
        mediaRegion
        meetingId
        externalMeetingId
      }
      chimeAttendee {
        attendeeId
        externalUserId
        joinToken
      }
    }
  }
`;

export const useGetTelehealthAttendeeQuery = (options?: Options): Result => {
  return useQuery<Data, Variables>(GetTelehealthAttendeeQuery, options);
};
